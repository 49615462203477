import { IEnsProfile } from "@/app/types";
import { IWeb3Profile } from "@/app/lib/types";
import { COLOR_PALETTE } from "@/app/utils/constants";

const PINATA_GATEWAY_URL =
  process.env.NEXT_PUBLIC_PINATA_GATEWAY_URL ||
  "https://gateway.pinata.cloud/ipfs";

export const trimAddress = (address: string) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const trimTimeTooltip = (time: string) => {
  if (!time) return "";
  return `${time.slice(0, 10) + " " + time.slice(11, 19)}`;
};

export const parseErrorMessage = function (err: any) {
  const parseError = JSON.parse(JSON.stringify(err, null, 2));
  const message =
    parseError?.info?.error?.message ||
    parseError?.reason ||
    err?.message ||
    "Something went wrong. Please try again.";
  return message;
};

export const findEnsProfile = (profiles: IWeb3Profile[]) => {
  if (!profiles || !profiles.length) return null;

  const ensProfile = profiles.find(
    (profile) => profile?.platform?.toLocaleLowerCase() === "ens",
  );
  const account = ensProfile?.address || "";

  if (!account) return null;
  const avatar = ensProfile?.avatar || null;
  const displayName = ensProfile?.identity || account;
  const description = ensProfile?.description || null;

  return { avatar, displayName, description } as IEnsProfile;
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const fixIPFSImgURL = (imgUrl: string | null | undefined) => {
  if (!imgUrl || imgUrl.includes("eip155")) {
    // TODO: implement resolver for NFTs (ENS impl)
    return "";
  } else if (imgUrl.startsWith("ipfs")) {
    const strs = imgUrl.split("/");
    const cid = strs.find((str) => str.length > 30);
    return `${PINATA_GATEWAY_URL}/${cid}`;
  } else if (imgUrl.startsWith("https") || imgUrl.startsWith("data")) {
    return imgUrl;
  } else {
    return "";
  }
};

export const rgbDataURL = (r: number, g: number, b: number) => {
  const keyStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);

  return `data:image/png;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;
};

export const isValidETHAddress = (str: string) => {
  const regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);
  return regex.test(str);
};

export const getUniqueColorByChoice = (choice: string, choices: string[]) => {
  const label = choice.trim().toLowerCase();
  switch (label) {
    case "for":
      return COLOR_PALETTE[0];
    case "against":
      return COLOR_PALETTE[1];
    case "abstain":
      return COLOR_PALETTE[2];
    default:
      const index = choices.indexOf(choice);
      return COLOR_PALETTE[index + 3] || "#000000";
  }
};
